<template>
  <div class="select" :class="{ disabled: disabled }">
    <div v-if="options.length < maxOptions">
      <ul>
          <li v-bind:class="index == selected ? 'selected' : 'not-selected'" v-for="(option, index) in options" :key="index" @click="onSelect(index)">
            {{ option }}
          </li>
        </ul>
       
    </div>
    <div v-else>

      <div class="expand-panel-container"  :class="{ 'open': open }">
        <div class="index-bar" @click="open = !open"  :class="{ 'open': open, 'no-selection': (selected < 0)}">
            <div class="title" :class="{'open' : open }">{{ title }}</div>
  <!--           <div class="control">
              <control-arrow :class="{ 'open': open , 'close': !open}" />
            </div> -->
        </div>
        <div class="content" :class="{ 'content-height': open}">
          <ul>
            <li v-bind:class="index == selected ? 'selected' : 'not-selected'" v-for="(option, index) in options" :key="index" @click="onSelect(index)">
              {{ option }}
            </li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  props: {
   label: String,
   options: Array,
   selected: Number,
   maxOptions: Number,
   noSelection: String,
   dense:true,
   disabled: Boolean,
  }, 
  data() {
    return {
      open: false,
      placeholder: ''
    }
  },
  mounted() {
     this.noSelection != '' ? this.placeholder = this.noSelection : this.placeholder = 'Bitte wählen';
  },
  computed: {
 /*    options () {
      return this.$store.state.options
    } */
    title() {
      return ( this.selected >= 0 ?  this.options[this.selected] : this.placeholder  );
      } 
  },
  methods: {
    onSelect(index) {
      this.open = false;
      this.$emit('update', index)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


.expand-panel-container {
   margin-bottom: 0px;
   width: calc(100%);
   max-width: 300px;
   display: float;


   ul {
   list-style-type: none;
   padding: 0;
   float:left;
   margin: 0 !important;
   width: 100%;
   }

   li {
   display: flex;
   justify-content: left;
   align-items: center;
   margin: 10px 0px;
   padding: 0px 10px;
   height: 50px;
   border: 1px solid $single-select-border;
   width: 100%;
    
   font-weight: normal;
   text-align: left;
   cursor: pointer;
   }

   li:hover {
   background-color: $hover;
   }

   .selected {
   background-color: $single-select-selected-bg;
   color: $single-select-selected-color !important;
   }

   .selected .description {
      color: $single-select-selected-color !important;
   }

   .not-selected {
   background-color: white;
   }

   .no-selection {
      background-color: #aaa !important;
      color: #fff !important;
   }

   .selection {
      color: #333;
   }
   .index-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100%);
      height: 50px;
      padding: 0 40px 0 10px;
      background-color: white;
      color: $single-select-color;
      border: 1px solid $single-select-border;
      cursor: pointer;

      &:hover {
         background-color: $hover;
      }
      
      &.open {
        background-color: white;
        color: #dedede;
      }
  
      .title {
         font-weight: normal;
         cursor: pointer;
      }

      .control {
        width: 1em;
        fill: $single-select-color;
        margin-right: 10px;
         cursor: pointer;
        
        .close {
          transform: rotate(180deg);
          transition: transform .2s ease-in-out;
        }

        .open {
          transform: rotate(0deg);
          transition: transform .2s ease-in-out;
        }

      }
    }

    .content-height {
      max-height: 800px!important;
    }

    .content {
      width: calc(100%);
      max-height: 0;
      overflow: hidden; 
      transition: all .3s ease-in-out; 
    }
  }

</style>
