
<template>
<div>         
   <div id="logo">
      <div class="container">
         <a :href="'https://www.apollo.de'+trackinglink" @click="statistik( 'c_logo');" target="_blank" name="Apollo Website" style="text-decoration: underline; outline: none; color: #fff;">
            <svg class="c-logo__image" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="160.9" height="33.2" viewBox="-201.7 403.9 160.9 33.2" xml:space="preserve">
               <g>
                  <g transform="translate(-117 -41)">
                     <g transform="translate(117 41)">
                        <path class="st0" style="fill:#054f9d" d="M-84.6 410c0 .9-.8 1.7-1.7 1.7-.9 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7 1.7.8 1.7 1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-90.1 412.5c0 .8-.6 1.4-1.4 1.4s-1.4-.6-1.4-1.4c0-.8.6-1.4 1.4-1.4s1.4.6 1.4 1.4"></path>
                        <path class="st0" style="fill:#054f9d" d="M-69.7 410c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-67.1 405.7c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-78.2 420.5c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-73 406.3c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-78.8 407.8c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8c1 .1 1.8.9 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-74.9 411.4c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-77.8 414.5c.8.6.9 1.8.3 2.5-.6.8-1.8.9-2.6.3s-.9-1.8-.3-2.5c.6-.8 1.8-.9 2.6-.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-80.5 412.5c0 .9-.8 1.7-1.7 1.7-.9 0-1.7-.8-1.7-1.7s.8-1.7 1.7-1.7c1 0 1.7.7 1.7 1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-82.2 416.6c0 .9-.7 1.6-1.6 1.6s-1.6-.7-1.6-1.6c0-.9.7-1.6 1.6-1.6s1.6.7 1.6 1.6"></path>
                        <path class="st0" style="fill:#054f9d" d="M-83 420.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5c.9 0 1.5.6 1.5 1.5"></path>
                        <path class="st0" style="fill:#054f9d" d="M-90.7 416.7c0 .6-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2"></path>
                        <path class="st0" style="fill:#054f9d" d="M-94.8 415.6c0 .6-.5 1.1-1.1 1.1s-1.1-.5-1.1-1.1c0-.6.5-1.1 1.1-1.1.6.1 1.1.5 1.1 1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-90.8 420.5c0 .6-.5 1.2-1.2 1.2s-1.2-.5-1.2-1.2c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2"></path>
                        <path class="st0" style="fill:#054f9d" d="M-86.8 418.6c0 .7-.6 1.3-1.3 1.3-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3s1.3.6 1.3 1.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-86.1 414.6c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5 1.5.7 1.5 1.5"></path>
                        <path class="st0" style="fill:#054f9d" d="M-53.2 410c0 .9.8 1.7 1.7 1.7s1.7-.8 1.7-1.7-.8-1.7-1.7-1.7c-1 0-1.7.8-1.7 1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-47.8 412.5c0 .8.6 1.4 1.4 1.4.8 0 1.4-.6 1.4-1.4 0-.8-.6-1.4-1.4-1.4s-1.4.6-1.4 1.4"></path>
                        <path class="st0" style="fill:#054f9d" d="M-68.2 410c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.8-1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-59.7 420.5c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.8-1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-64.9 406.3c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8c-.9 0-1.8.8-1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-59.1 407.8c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8c-1 .1-1.8.9-1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-63 411.4c0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8-.8-1.8-1.8-1.8-1.8.8-1.8 1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-60 414.5c-.8.6-.9 1.8-.3 2.5.6.8 1.8.9 2.6.3s.9-1.8.3-2.5c-.7-.8-1.9-.9-2.6-.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-57.4 412.5c0 .9.8 1.7 1.7 1.7s1.7-.8 1.7-1.7-.8-1.7-1.7-1.7c-.9 0-1.7.7-1.7 1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-55.7 416.6c0 .9.7 1.6 1.6 1.6.9 0 1.6-.7 1.6-1.6 0-.9-.7-1.6-1.6-1.6-.9-.1-1.6.7-1.6 1.6"></path>
                        <path class="st0" style="fill:#054f9d" d="M-54.9 420.5c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.6-1.5 1.5"></path>
                        <path class="st0" style="fill:#054f9d" d="M-47.2 416.7c0 .6.5 1.2 1.2 1.2s1.2-.5 1.2-1.2c0-.6-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2"></path>
                        <path class="st0" style="fill:#054f9d" d="M-43 415.6c0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1c0-.6-.5-1.1-1.1-1.1-.6.1-1.1.5-1.1 1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-47.1 420.5c0 .6.5 1.2 1.2 1.2s1.2-.5 1.2-1.2c0-.6-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2"></path>
                        <path class="st0" style="fill:#054f9d" d="M-51 418.6c0 .7.6 1.3 1.3 1.3s1.3-.6 1.3-1.3c0-.7-.6-1.3-1.3-1.3-.8 0-1.3.6-1.3 1.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-51.8 414.6c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5-.7-1.5-1.5-1.5-1.5.7-1.5 1.5"></path>
                        <path class="st0" style="fill:#054f9d" d="M-84.6 430.9c0-.9-.8-1.7-1.7-1.7-.9 0-1.7.8-1.7 1.7s.8 1.7 1.7 1.7 1.7-.8 1.7-1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-90.1 428.4c0-.8-.6-1.4-1.4-1.4s-1.4.6-1.4 1.4.6 1.4 1.4 1.4 1.4-.6 1.4-1.4"></path>
                        <path class="st0" style="fill:#054f9d" d="M-67.1 435.3c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-73 434.6c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-78.8 433.1c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-77.8 426.4c.8-.6.9-1.8.3-2.5-.6-.8-1.8-.9-2.6-.3-.8.6-.9 1.8-.3 2.5.6.8 1.8 1 2.6.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-82.2 424.3c0-.9-.7-1.6-1.6-1.6s-1.6.7-1.6 1.6.7 1.6 1.6 1.6 1.6-.6 1.6-1.6"></path>
                        <path class="st0" style="fill:#054f9d" d="M-94.8 425.3c0-.6-.5-1.1-1.1-1.1s-1.1.5-1.1 1.1.5 1.1 1.1 1.1 1.1-.5 1.1-1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-86.8 422.4c0-.7-.6-1.3-1.3-1.3-.7 0-1.3.6-1.3 1.3s.6 1.3 1.3 1.3c.7-.1 1.3-.6 1.3-1.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-53.2 430.9c0-.9.8-1.7 1.7-1.7s1.7.8 1.7 1.7-.8 1.7-1.7 1.7c-1 0-1.7-.8-1.7-1.7"></path>
                        <path class="st0" style="fill:#054f9d" d="M-47.8 428.4c0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4s-.6 1.4-1.4 1.4-1.4-.6-1.4-1.4"></path>
                        <path class="st0" style="fill:#054f9d" d="M-64.9 434.6c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8c-.9 0-1.8-.8-1.8-1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-59.1 433.1c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8"></path>
                        <path class="st0" style="fill:#054f9d" d="M-60 426.4c-.8-.6-.9-1.8-.3-2.5.6-.8 1.8-.9 2.6-.3.8.6.9 1.8.3 2.5-.7.8-1.9 1-2.6.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-55.7 424.3c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6c-.9.1-1.6-.6-1.6-1.6"></path>
                        <path class="st0" style="fill:#054f9d" d="M-43 425.3c0-.6.5-1.1 1.1-1.1s1.1.5 1.1 1.1-.5 1.1-1.1 1.1-1.1-.5-1.1-1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-51 422.4c0-.7.6-1.3 1.3-1.3s1.3.6 1.3 1.3-.6 1.3-1.3 1.3c-.8-.1-1.3-.6-1.3-1.3"></path>
                        <path class="st0" style="fill:#054f9d" d="M-94.8 420.5c0 .6-.5 1.1-1.1 1.1s-1.1-.5-1.1-1.1.5-1.1 1.1-1.1 1.1.5 1.1 1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-43 420.5c0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1-.5-1.1-1.1-1.1-1.1.5-1.1 1.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-153.1 429.1c-6.8 0-7.7-3.8-7.7-8.6s.9-8.6 7.7-8.6 7.6 3.8 7.6 8.6c.1 4.7-.7 8.6-7.6 8.6m0-14.7c-3.8 0-4 2.2-4 6.1 0 3.8.2 6 4 6s4-2.2 4-6c0-3.9-.2-6.1-4-6.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-135.7 428.1c0 .2-.1.4-.3.5-.3.2-1.2.4-2.6.4-2.5 0-3.9-1.3-3.9-3.7v-19.1c0-.3.2-.5.5-.5l2.6-.5h.1c.3 0 .4.2.4.5V425c0 1.1.7 1.3 1.3 1.3.5 0 .8-.1 1.2-.1.2 0 .3 0 .3.3l.4 1.6z"></path>
                        <path class="st0" style="fill:#054f9d" d="M-126.6 428.1c0 .2-.1.4-.3.5-.3.2-1.2.4-2.6.4-2.5 0-3.9-1.3-3.9-3.7v-19.1c0-.3.2-.5.5-.5l2.6-.5h.1c.3 0 .4.2.4.5V425c0 1.1.7 1.3 1.3 1.3.5 0 .8-.1 1.2-.1.2 0 .3 0 .3.3l.4 1.6z"></path>
                        <path class="st0" style="fill:#054f9d" d="M-117.9 429.1c-6.8 0-7.7-3.8-7.7-8.6s.9-8.6 7.7-8.6 7.6 3.8 7.6 8.6c.1 4.7-.8 8.6-7.6 8.6m0-14.7c-3.8 0-4 2.2-4 6.1 0 3.8.2 6 4 6s4-2.2 4-6c0-3.9-.2-6.1-4-6.1"></path>
                        <path class="st0" style="fill:#054f9d" d="M-180.4 428.3c0 .3-.2.3-.4.3h-3.1c-.2 0-.5-.2-.5-.4l-2.9-8.7h-7.6l-2.9 8.7c-.1.2-.3.3-.5.3h-3c-.3 0-.4-.1-.4-.4v-.2l7.7-22.1c.1-.2.4-.4.6-.4h4.7c.2 0 .5.2.6.4l7.6 22.1c.1.3.1.4.1.4zm-10.5-19.9h-.4l-2.7 8.3h5.9l-2.8-8.3z"></path>
                        <path class="st0" style="fill:#054f9d" d="M-170 411.9c-2.1 0-4.5.4-6.3 1-1.5.5-1.9 1-1.9 2.5v19.4c0 .3.2.5.6.5l2.5-.5c.3-.1.5-.2.5-.6v-5.6c1.1.3 2.6.5 4.2.5 5.8 0 7.1-3.2 7.1-8.6 0-5.1-.8-8.6-6.7-8.6m-1.1 14.6c-1.8 0-3.2-.3-3.5-.4v-11c.4-.1 2.4-.6 3.8-.6 2.6 0 3.9.6 3.9 6 0 4.8-1 6-4.2 6"></path>
                        <path class="st1" style="fill:#e9531e" d="M-67.4 413.6c0 .8-.7 1.5-1.5 1.5-.9 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.6 1.5 1.5"></path>
                        <path class="st1" style="fill:#e9531e" d="M-71.9 414c.5.7.3 1.6-.3 2.1-.7.5-1.7.3-2.2-.3-.5-.7-.3-1.6.3-2.1.8-.5 1.7-.4 2.2.3"></path>
                        <path class="st1" style="fill:#e9531e" d="M-75.2 417c.8.3 1.3 1.1 1 1.9s-1.1 1.2-1.9 1c-.8-.3-1.3-1.1-1-1.9.2-.8 1.1-1.3 1.9-1"></path>
                        <path class="st1" style="fill:#e9531e" d="M-66 414c-.5.7-.3 1.6.3 2.1s1.7.3 2.2-.3.3-1.6-.3-2.1c-.8-.5-1.7-.4-2.2.3"></path>
                        <path class="st1" style="fill:#e9531e" d="M-62.7 417c-.8.3-1.3 1.1-1 1.9s1.1 1.2 1.9 1c.8-.3 1.3-1.1 1-1.9-.2-.8-1.1-1.3-1.9-1"></path>
                        <path class="st1" style="fill:#e9531e" d="M-69.7 431c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                        <path class="st1" style="fill:#e9531e" d="M-74.9 429.5c0-1-.8-1.8-1.8-1.8s-1.8.8-1.8 1.8.8 1.8 1.8 1.8 1.8-.8 1.8-1.8"></path>
                        <path class="st1" style="fill:#e9531e" d="M-80.5 428.4c0-.9-.8-1.7-1.7-1.7-.9 0-1.7.8-1.7 1.7s.8 1.7 1.7 1.7c1 0 1.7-.7 1.7-1.7"></path>
                        <path class="st1" style="fill:#e9531e" d="M-90.7 424.3c0-.6-.5-1.2-1.2-1.2s-1.2.5-1.2 1.2c0 .6.5 1.2 1.2 1.2.7-.1 1.2-.6 1.2-1.2"></path>
                        <path class="st1" style="fill:#e9531e" d="M-86.1 426.4c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5.7 1.5 1.5 1.5c.8-.1 1.5-.7 1.5-1.5"></path>
                        <path class="st1" style="fill:#e9531e" d="M-67.4 427.3c0-.8-.7-1.5-1.5-1.5-.9 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c.8.1 1.5-.6 1.5-1.5"></path>
                        <path class="st1" style="fill:#e9531e" d="M-71.9 426.9c.5-.7.3-1.6-.3-2.1-.7-.5-1.7-.3-2.2.3-.5.7-.3 1.6.3 2.1.8.5 1.7.4 2.2-.3"></path>
                        <path class="st1" style="fill:#e9531e" d="M-75.2 423.9c.8-.3 1.3-1.1 1-1.9-.3-.8-1.1-1.2-1.9-1-.8.3-1.3 1.1-1 1.9.2.8 1.1 1.3 1.9 1"></path>
                        <path class="st1" style="fill:#e9531e" d="M-68.2 431c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8"></path>
                        <path class="st1" style="fill:#e9531e" d="M-63 429.5c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8"></path>
                        <path class="st1" style="fill:#e9531e" d="M-57.4 428.4c0-.9.8-1.7 1.7-1.7s1.7.8 1.7 1.7-.8 1.7-1.7 1.7c-.9 0-1.7-.7-1.7-1.7"></path>
                        <path class="st1" style="fill:#e9531e" d="M-47.2 424.3c0-.6.5-1.2 1.2-1.2s1.2.5 1.2 1.2c0 .6-.5 1.2-1.2 1.2-.7-.1-1.2-.6-1.2-1.2"></path>
                        <path class="st1" style="fill:#e9531e" d="M-51.8 426.4c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5c-.8-.1-1.5-.7-1.5-1.5"></path>
                        <path class="st1" style="fill:#e9531e" d="M-66 426.9c-.5-.7-.3-1.6.3-2.1.7-.5 1.7-.3 2.2.3.5.7.3 1.6-.3 2.1-.8.5-1.7.4-2.2-.3"></path>
                        <path class="st1" style="fill:#e9531e" d="M-62.7 423.9c-.8-.3-1.3-1.1-1-1.9.3-.8 1.1-1.2 1.9-1 .8.3 1.3 1.1 1 1.9-.2.8-1.1 1.3-1.9 1"></path>
                     </g>
                  </g>
               </g>
            </svg>
         </a>
         <!--        <img src="@/assets/img/apollo_logo_transparent.png" /> -->
      </div>
   </div>
   <div id="header">
      <div class="container">
         <img src="@/assets/img/header_layoutbild.jpg" />
         <div class="box-grusswort"><span class="title">Postkarte für<br /> die beste Mama der Welt</span><br /><br /><span class="subline">Noch bis 25.04.2021 gestalten und kostenfrei versenden</span></div>
      </div>
   </div>
   <div id="form">
      <div class="formular container">
         <h1>Ohje!</h1>
         <br/>
         <div class="col-100">
            <h2>Leider ist unsere Aktion zur Gestaltung einer Muttertags-Postkarte mit dem 25. April 2021 zu Ende gegangen.</h2>
         </div>
   </div>
   </div>
     <div id="newsletter">
      <div class="container">          
            <h1>Noch kein Newsletter-Abo? Jetzt anmelden!</h1>
         <div class="col-100">           
            <ul>
               <li>Keine Aktionen mehr verpassen</li>
               <li>5 € Gutschein direkt bei der Newsletter-Anmeldung</li>
               <li>Die topktuellen Trends direkt zu Ihnen ins Postfach</li>
               <li>Immer tolle Angebote entdecken</li>
            </ul>              
         </div>
         <div class="col-100">
            <a :href="'https://www.apollo.de/newsletter'+trackinglink" @click="statistik( 'c_cta_newsletter');" target="_blank" name="Anmelden beim Apollo Newsletter" style="text-decoration: none; outline: none; color: #fff;">
               <button class="btn-newsletter">Newsletter abonnieren</button>
            </a>
         </div>
      </div>
   </div>

<div id="vorbeischauen">
   <div class="container">
      <h2>Schauen Sie doch mal wieder vorbei:</h2>
         <div class="col-100">  
            <div class="col-50">
               <a :href="'https://filialen.apollo.de'+trackinglink" @click="statistik( 'c_cta_filiale');"  target="_blank" name="Apollo Filialen" style="text-decoration: underline; outline: none; color: #fff;">
                  <button class="btn">
                     In Ihrer Apollo-Filiale
                  </button>
               </a>
            </div>         
            <div class="col-50">
               <a :href="'https://www.apollo.de'+trackinglink"  @click="statistik( 'c_cta_apollode');"  target="_blank" name="Apollo Website" style="text-decoration: underline; outline: none; color: #fff;">
                  <button class="btn">
                     Online auf apollo.de
                  </button>
               </a>
               </div>

         </div>
   </div>
</div>

<div id="payback">
   <div class="container">
      <div class="col-100">
      <div class="col-50">
         Sie erhalten für jeden Einkauf bei <span style="white-space: nowrap">Apollo</span> <span style="white-space: nowrap">PAYBACK</span>-Punkte
      </div>
      <div class="col-50">
         <a :href="'https://www.apollo.de/service/apollo-service/payback'+trackinglink" @click="statistik( 'c_payback');" target="_blank" name="Payback mit Apollo" style="text-decoration: none; outline: none;">
         <img src="@/assets/img/payback.png" width="300" class="payback-logo" />
         </a>
      </div>
      </div>
   </div>
</div>

<div style="margin-bottom:-12px"><svg viewBox="0 0 450 29"><path fill="#e4eaef" stroke="#e4eaef" stroke-miterlimit="10" d="m 0.50,18.6 c 0,0 122.6,-34.9 230.0,-7.0 107.6,27.9 219.0,-6.6 219.0,-6.6 V 29.55 H 0.5 Z"></path></svg>
</div>
<div id="disclaimer" >
<div class="container">   
<p style="text-align: left; font-family: sans-serif; font-size: 0.7em; line-height: 1.3em; color: #000; font-weight: normal;">
* Gültig bis 08.06.2021. Dieser Rabatt ist nur kombinierbar mit unseren Aktionen „Eine zahlen, Zwei bekommen“ (Brille/Sonnenbrille) und „Nimm 4, zahl 3“ (Kontaktlinsen/Pflegemittel), ist nicht übertragbar und nicht nachträglich einlösbar. Weitere Informationen finden Sie auf <a :href="'https://www.apollo.de/lp/aktionen'+trackinglink" target="_blank" @click="statistik( 'c_aktionen');" name="Link zu Apollo.de Aktionen" style="text-decoration: none; outline: none; color: #000;" title="Apollo Aktionen">www.apollo.de/lp/aktionen</a>. Gilt im Online-Shop nicht auf bereits reduzierte Ware. KG 80
<br /><br /><br /><br />
Internetseite: <a :href="'https://www.apollo.de'+trackinglink" @click="statistik( 'c_apollo.de');" target="_blank" name="Link zu Apollo.de" style="text-decoration: none; outline: none; color: #000;" title="Günstige Brillen bei Apollo - Ihr Augenoptiker">www.apollo.de</a><br>
Datenschutz: <a :href="'https://www.apollo.de/legal/datenschutz'+trackinglink" target="_blank" name="Link zu Apollo.de Datenschutz" style="text-decoration: none; outline: none; color: #000;" title="Apollo Datenschutz">www.apollo.de/legal/datenschutz</a><br/>
Kontakt: <a href="mailto:kundenservicecenter@apollo-optik.com" name="Link Email Feedback Impressum" style="text-decoration: none; outline: none; color: #000;" title="Schreiben Sie uns Ihre Wünsche - wir freuen uns über Ihr Feedback!">kundenservicecenter@apollo-optik.com</a>
<br><br>
Impressum:<br />Anbieter: Apollo-Optik Holding GmbH &amp; Co. KG, Wallenrodstraße 3, 91126 Schwabach.<br />
 <br />
Amtsgericht Nürnberg HRA 14041, Komplementärin: Apollo-Optik Beteiligungs GmbH, Sitz: Schwabach, Amtsgericht Nürnberg HRB 22424, Umsatzsteueridentifikationsnummer DE814565082, Geschäftsführer: Dr. Jörg Ehmer
<br />
Die EU-Kommission hat eine Internetseite zur Online-Streitbeilegung zwischen Unternehmern und Verbrauchern (OS-Plattform) eingerichtet, die Sie unter 
<a href="https://ec.europa.eu/consumers/odr" target="_blank" style="text-decoration: none; outline: none; color: #000; ">https://ec.europa.eu/consumers/odr</a> erreichen.<br/>
Hinweis gemäß Verbraucherstreitbeilegungsgesetz: Die Apollo-Optik Holding GmbH & Co. KG nimmt an einer alternativen Streitbeilegung durch eine Verbraucherschlichtungsstelle nicht teil.
<br/><br/>
<br><br> © Apollo-Optik 2021
</p>
</div>
</div>

</div>
</template>

<script>
import axios from "axios";

export default {
   components: {},
     data() {
      return {
         trackinglink: '?utm_source=mktpush&utm_medium=email&utm_campaign=MuttertagsAktion_LP_042021&utm_term=NL_promo&utm_content=Uni'
      };
     }
}
</script>

<style scoped lang="scss">

.error-message, .success-message, .send-error, .send-success {
   font-weight: normal;
   font-size: 0.8em;
   display: block;
   padding: 10px 0px;
   margin: 0px;

   ul {
       list-style-type: disc;
       margin-left:20px;
   }
}

.texthinweis {
   display: block;
   padding: 10px;
   font-size: 0.8em;
   font-weight: normal;

   &.error  {
      color: red
}
}

.send-error, .send-success {
   font-size: 1.1em;
   margin: 0 auto;
}

.form-error {
   color: red
}


#logo {
   width: 100%;

   .container {
      display: flex;
      justify-content: left;
      padding: 20px;

      img {
         width: 100%;
         height: 100%;
         max-width: 300px;
      }
   }
}


#header {
   width: 100%;
   background-color: $bg-header-periphery;
   margin-bottom: 55px;
   
   .container {
      position: relative;
      background-color: $bg-header;   
      padding: 0px; 

      .box-grusswort{
         position: absolute;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-content: center;
         top:5%;
         bottom: 5%;
         left: 3%;
         width:35%;
         text-align: left;
         hyphens: none; 
         color: white;
  
         .title {
            font-size: 50px;
            line-height: 1em;

            @media screen and (max-device-width: 1200px),
            screen and (max-width: 1200px) {
               font-size: 4.17vw;
            }
         }
         .subline {
            font-size: 28px;
            line-height: 1em;

             @media screen and (max-device-width: 1200px),
            screen and (max-width: 1200px) {
               font-size: 2.33vw;
            }
         }

      }

      img {
         width: 100%;
         height: 100%;
         display: block;
      }
   }
}

#form {
   width: 100%;
   background-color: $bg-form-periphery;
   margin-bottom: 55px;
   font-size: 1.5em;

   .motivwahl {
      display: flex;
      justify-content: space-between;
      background-color: $bg-form;
      width: 100%;
      flex-direction: column;

  /*     @media screen and (max-device-width: 8000px), 
      screen and (max-width: 8000px) {
         flex-direction: column;
      } */

      .intro {
         width: 100%;
         text-align: left;

    /*      @media screen and (max-device-width: 8000px), 
         screen and (max-width: 8000px) {
            width: 100%;
         } */
      }

      .choices {
         display: flex;
         justify-content: space-between;
         width: 100%;
         margin-top: 40px;

     /*     @media screen and (max-device-width: 8000px), 
         screen and (max-width: 8000px) {
            width: 100%;
           
         } */

         @media screen and (max-device-width: 800px), 
         screen and (max-width: 800px) {
            flex-direction: column;
            align-items: center;
         }


         .choice {
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;

            @media screen and (max-device-width: 800px), 
            screen and (max-width: 800px) {
               width: 95%;
               margin-bottom: 60px;
            }

            img {
               border: 1px solid #444;
               margin-bottom: 20px;

            } 
         }
      }
   }

   .formular {
     
      background-color: $bg-form;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
  
      label {
         display: block;
         width: 100%;
         font-weight: normal;
         margin: 30px 0 10px 0;
      }
      
      textarea, input { 
         width: 100%; 
         margin: 0 !important;
         padding: 10px !important;
      } 

      .col-100 { 
         width: 100%;
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         justify-content: space-between;
         text-align: left;
         padding: 0px;
         margin: 0px;

         &.hinweis {
            font-weight: normal;
            margin-top: 40px;
         }

         &.rechtlicher-hinweis {
            font-weight: normal;
            margin-top: 20px;
            margin-bottom: 30px;
            font-size: 1em;
         }
     
   
         .col-50 {
            width: 47%;
         }

         .col-33 {
            width: 30%;
         }

         .col-66 {
            width: 67%;
         }
        
      
         @media screen and (max-device-width: 599px),
         screen and (max-width: 599px) {
            .col-50, .col-33, .col-66 {
               width: 100%;
            }
         }
      }


   }
}

#action {
   width: 100%;
   background-color: $bg-action-periphery;
   margin-bottom: 55px;
      font-size: 1.5em;

   .container {
      background-color: $bg-action;
  
       h1 {
            transition: all 0.5s ease-in-out;
            opacity: 1;

            &.aus {
               opacity: 0;
            }
         }

      .col-100 {
         display: flex;
         flex-direction: column;
         flex-wrap: wrap;
         justify-content: left;
         text-align: left;
         width: 100%;
         max-width: 1050px;
         padding: 0px;
         margin: 50px 0;

         &.hinweis {
            font-weight: normal;
            margin-top: 0px;
         }

         &.center {
             justify-content: center;
         }

         .titel {
            margin: 20px 0px;
            transition: all 0.5s ease-in-out;
            opacity: 1;

            &.aus {
               opacity: 0;
               display: none;
            }
         }

         a {
            margin: 0px  auto;
            max-width: 550px;
            width: 100%;
         }

         .btn {
            max-width: 550px;
            width: 100%;
            margin: 40px auto;
            padding: 10px;
            border: none;
            border-radius: 1.4em;
            background: $apollo-orange;
            text-align: center;
            text-decoration: none;
            font-size: 1em;
            font-weight: normal;
            color: white;
            cursor: pointer;
            position: relative;

            transition: all 0.5s ease-in-out;
            opacity: 1;

            &.aus {
               opacity: 0;
                display: none;
            }

            &:hover {
               box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
               background: lighten($apollo-orange, 5%);
               transform: scale(1.05);
            }

            &:active {
               background: darken($apollo-orange, 5%);
            }

            &.error {
               background: red;
                
            }


         }

      }
   }
}

#payback {
   width: 100%;
   background-color: $bg-payback-periphery;

   .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $bg-payback;
      font-size: 1.8em;
     /*  flex-wrap: wrap; */
      font-weight: normal;

      .col-100 {
         width: 100%;
         display: flex;
         flex-direction: row;
         flex-wrap: wrap;
         justify-content: space-between;
         text-align: left;
         padding: 0px;
         margin: 0px;
         align-items: center;
      }

      .col-50 {
         width: 49%;  
         padding: 0px 1% 0px 0px ;
         text-align:left; 
         margin-bottom: 20px;
      

          @media screen and (max-device-width: 700px),
         screen and (max-width: 700px) {
            width: 100%;
            text-align: center;
         } 

         .payback-logo {
            width: 70%;
            max-width: 250px;
         }
      }
   }
}

#vorbeischauen {
   width: 100%;
   background-color: white;
   margin-bottom: 40px;
   font-size: 1.5em;

   .container {
      background-color:  white;
  
      h1, h2 {
         font-weight: bold;
      }
      .col-100 {
         display: flex;
         justify-content: space-between;
         width: 100%; 
         text-align:left; 
         margin: 0px 0px;

         
         a {
            margin: 0px  auto;
            max-width: 550px;
            width: 100%;
         }

         .col-50 {
            width: 48%;
            display: flex;
             justify-content: center;

            .btn {
            max-width: 520px;
            width: 100%;
            margin: 20px auto;
            padding: 10px;
            border: none;
            border-radius: 1.4em;
            background: $apollo-orange;
            text-align: center;
            text-decoration: underline;
            font-size: 1em;
            font-weight: normal;
            color: white;
            cursor: pointer;
            position: relative;

            &:hover {
               box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
               background: lighten($apollo-orange, 5%);
               transform: scale(1.05);
            }

            &:active {
               background: darken($apollo-orange, 5%);
            }

         }
         }

      
         @media screen and (max-device-width: 700px),
         screen and (max-width: 700px) {
            .col-50 {
               width: 100%;
            }

            flex-direction: column;

         }
      
      }
   }
}


#disclaimer {
   width: 100%;
   background-color: $bg-disclaimer-periphery;

   .container {
      display: flex;
      justify-content: left;
      background-color: $bg-disclaimer;
      font-size: 1.4em;
   }
}

#newsletter {
   width: 100%;
   background-color: $bg-form-periphery;
   margin-bottom: 40px;

   .container {
      display: flex;
      flex-direction: column;
      background-color: $bg-form;

      .col-100 {
         display: flex;
         justify-content: space-between;
         width: 100%; 
         text-align:left; 
         margin: 20px auto;

         a {
            margin: 0px  auto;
            max-width: 550px;
            width: 100%;
         }
         
         ul {
            font-size: 1.5em;
            font-weight: normal;
            list-style: url('~@/assets/img/haken25.png');
            margin-left: 30px;
         }

         .col-50 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 48%;  
            margin: 0;


         }
      }


      .btn-newsletter {
         max-width: 550px;
         width: 100%;
         margin: 0 auto;
         height: 2em;
         border: none;
         border-radius: 1.4em;
         border: 1px solid #444;
         background: $white;
         text-align: center;
         text-decoration: none;
         font-size: 1.4em;
 
         font-weight: normal;
         color: #444;
         cursor: pointer;

         &:hover {
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
            transform: scale(1.05);
         }
      }
   }
}




textarea,
input[type="text"] {
   padding: 10px;
   font-size: 1em;
   border: 1px solid #333;
   font-family: "SofiaPro-Medium", Helvetica, Arial, sans-serif !important;
   font-weight: normal;
   resize: none;
}
/* Customize the label (the container) */
.radio-container {
   display: block;
   position: relative;
   padding-left: 35px;
   margin-bottom: 12px;
   cursor: pointer;
   font-size: 22px;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
   position: absolute;
   opacity: 0;
   cursor: pointer;
   height: 0;
   width: 0;
}

/* Create a custom radio button */
.checkmark {
   position: absolute;
   top: 0;
   left: 0;
   height: 25px;
   width: 25px;
   background-color: #fff;
   border: 1px solid #444;
   border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
   background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
   background-color: #E84379;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
   content: "";
   position: absolute;
   display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
   display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
   top: 6px;
   left: 6px;
   width: 11px;
   height: 11px;
   border-radius: 50%;
   background: white;
}

@media screen and (max-device-width: 1200px), screen and (max-width: 1200px) {
   /* .row {
   width: calc(100% - 40px);
   border-radius: 10px;
   margin: 0 auto;
} */
}

.spinner {
   position: relative;
}

.button--loading::after {
   content: "";

   width: 25px;
   height: 25px;
   margin: auto;
   border: 4px solid transparent;
   border-top-color: #fff;
   border-radius: 50%;
   animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
from {
   transform: rotate(0turn);
}

to {
   transform: rotate(1turn);
}
}

.sent-l-item-leave-to {
   position: relative;
}

.sent-l-enter-active {
   animation: zoomInUp 1.5s;
   animation-timing-function: ease-in-out;
}

.sent-l-leave-active {
   animation: zoomInUp reverse 1s;
   animation-timing-function: ease-in-out;
}

.sent-l-move {
   transition: transform 1s;
}


.stempel-item-leave-to {
   position: absolute;
}

.stempel-enter-active {
   animation: stempeln 1s;
   animation-timing-function: ease-in-out;
}

.stempel-leave-active {
   animation: stempeln reverse 0.8s;
   animation-timing-function: ease-in-out;
}

.stempel-move {
   transition: transform 1s;
}

   @keyframes stempeln {
   from {
      transform: translate3d(250%, 0, 0) skewX(15deg) scale(1.3);
      position: absolute;
      z-index: 100;
   }

   60% {
      transform: skewX(-15deg) scale(1.3);
   }

   80% {
      transform: skewX(5deg); 
   }

   to {
      transform: translate3d(0, 0, 0);
      position: absolute;
      z-index: 100;
   }
}

@keyframes zoomInUp {
  from {
    opacity: 0;
  }

  30% {
    opacity: 0;
    transform: scale3d(0.0, 0.0, 0.0) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 0.5;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes wobble {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}
.wobble {
  animation: wobble 0.3s;
  transform-origin: center;
}





@import url("https://p.typekit.net/p.css?s=1&k=zxs8gfx&ht=tk&f=4774.4859.16558&a=5632900&app=typekit&e=css");

@font-face {
font-family:"ff-market-web";
src:url("https://use.typekit.net/af/6b403f/0000000000000000773599f5/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/6b403f/0000000000000000773599f5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/6b403f/0000000000000000773599f5/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

@font-face {
font-family:"ff-market-web";
src:url("https://use.typekit.net/af/9ee3ad/0000000000000000773599f6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9ee3ad/0000000000000000773599f6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9ee3ad/0000000000000000773599f6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"ff-market-web-pro-condensed";
src:url("https://use.typekit.net/af/6c6d35/00000000000000003b9b06c3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/6c6d35/00000000000000003b9b06c3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/6c6d35/00000000000000003b9b06c3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;
}


.teststring {
  font-family: 'ff-market-web', Helvetica, Arial, sans-serif !important;
  // hyphens: none;
  font-weight: normal;
  background: white;
  font-size:20.8px;
}
</style>
