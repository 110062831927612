import Vue from 'vue'
import App from './App.vue'
import router from './router' 
/* import store from './store' */
// import axios from "axios"
/* import { jsPDF } from "jspdf"; */

/* import VueHtml2Canvas from 'vue-html2canvas';
Vue.use(VueHtml2Canvas); */

import BloomSingleSelect from '@/components/SingleSelect.vue'
import Postkarte from '@/components/Postkarte.vue'

Vue.component('bloom-single-select', BloomSingleSelect )
Vue.component('bloom-postkarte', Postkarte )
Vue.component('control-arrow', require('./assets/controls/svg/arrow-ico.svg')  )
Vue.config.productionTip = false

Vue.prototype.$_APOLLO_API_URL = 'https://apollo-api.atbloom.de/api'  
 // Vue.prototype.$_APOLLO_API_URL = 'http://localhost:8000/api'

new Vue({
   router, 
/*   store, */
  render: h => h(App)
}).$mount('#app')


